import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";
import HBoxTree from "global/components/Page/InputHtml/HBoxTree/HBoxTree";

class MaterialTreeList extends HFormUser {
    constructor(props) { super(props); }
    componentDidMount() {  this.readApi(); }
    //handleChange = name => event => {        this.setState({ error: "" });    };    
    getKey() {
        return {
            idRs: this.props.location.state.detIdRs
            , idCurso: this.props.location.state.detIdCurso
            , idMateria: this.props.location.state.detIdMateria
        }
    }
    readApi() {
        this.setState({ loading: true });
        this.post(Api.materialTreeLoad, this.getKey())
            .then(data => { this.stateHarrys(this, data) })
    }
    btHabilitar=(row)=> {
        this.setState({ loading: true });
        this.post(Api.materialTreeOk,{regRow:row
                                     ,idRs: this.props.location.state.detIdRs
                                     ,idMateria:this.props.location.state.detIdMateria
                                     ,sw:1
                                    })
            .then(data => { this.readApi() })
    }
    btDesHabilitar=(row)=> {
        this.setState({ loading: true });
        this.post(Api.materialTreeOkNo,{regRow:row
                                     ,idRs: this.props.location.state.detIdRs
                                     ,idMateria:this.props.location.state.detIdMateria
                                     ,sw:0
                                    })
            .then(data => { this.readApi() })
    }
    btnClickHtml = (row) => {
        let objKey = {
            detKeyItem: row.id
            , detIdRs: this.props.location.state.detIdRs
            , detIdCurso: this.props.location.state.detIdCurso
            , detIdMateria: this.props.location.state.detIdMateria
        }
        //console.log("idReg objKey",objKey)
        apiRuta.toRutaAdd(this, "Html", Api.materialTreeViewRoute, objKey)
    }
    btEnabled = (row) => {
        this.setState({ loading: true })
        this.post(Api.materialViewIdEnabled, {
            idKey: row.id
            , idRs: this.props.location.state.detIdRs
        })
            .then(data => { this.readApi() })
    }


    // Los botones deben estar antes que se llamen
    arrTit = [{ col: 'descripcion', label: 'Titulo' }
        //,{col:'fvisita',label:'Visita'}
        //,{col:'faprob',label:'Aprobado'}
        , { col: 'btnDataDwon', label: 'Apr.', accion: this.btEnabled, width: 60, clsIcono: "ico_up_down_cls", clsBoton: "col_up_down_cls" }
        , { col: 'btnHtml', label: 'Html', accion: this.btnClickHtml, width: 60, clsIcono: "far fa-address-card", clsBoton: "col_up_down_cls" }
        , { col: 'btnDes', label: 'Quitar', accion: this.btDesHabilitar, width: 60, clsIcono: "fas fa-remove", clsBoton: "col_up_down_cls" }
        , { col: 'btnAct', label: 'Activar', accion: this.btHabilitar, width: 60, clsIcono: "fas fa-check", clsBoton: "col_up_down_cls" }
    ]
    render() {
        if (this.state.loading) return (<ShowLoading />)
        return (<ContainerRightEdit
            contexto={this}
        >
            <HBoxTree key="KeyMat897"
                dataTree={this.state.resObj}
                dataTitulos={this.arrTit}
                expand={false}
            />
        </ContainerRightEdit>
        );
    }

}
export default WithNavigate(MaterialTreeList);