import React, { Component } from "react";
import Api from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";

import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";


class CursosActivos extends HFormUser {
    readApi = (stParamDict) => {
        this.post(Api.seccionesActivosList, { qryFiltro: stParamDict })
            .then(data => { this.stateHarrys(this, data) })
    }
    componentDidMount() {  this.readApi(""); }

    btAlum = row => {
        //console.log("alum",row)
        apiRuta.toRutaAdd(this, "Alumnos", Api.seccionesAlumnosRoute, { detRs: row })
    };
    btFechas = row => {
        //console.log("feca",row)
        apiRuta.toRutaAdd(this, "Fechas", Api.seccionesFechasRoute, { detRs: row })
    };
    btMaterial = row => {
        apiRuta.toRutaAdd(this, "Contenidos", Api.materialConteListRoute, { detRs: row, detIdCurso: row.cur_id })
    };
    btAsis = row => {
        apiRuta.toRutaAdd(this, "Asistencia", Api.seccionesAsistenciaRoute, { detRs: row })
    };
    btLab = row => {
        apiRuta.toRutaAdd(this, "Laboratorios", Api.seccionesLaboratoriosRoute, { detRs: row })
    };

    render() {
        if (this.state.loading) return (<ShowLoading />)
        const titulos = [
            { id: "ram_sigsec", label: "Sigla", align: "left", style: { width: "100px" } }
            , { id: "ram_descrip", label: "Descripción", align: "left" }
            , { id: "per_sigla", label: "Periodo", align: "right", style: { width: "80px" } }
            , { id: "totalum", label: "Alu.", align: "right", style: { width: "30px" } }
            , { id: "totclok", label: "CL Ok.", align: "right", style: { width: "30px" } }
            , { id: "bt_Aum", label: "Alum.", align: "center", accion: this.btAlum, clsIcono: "fa fa-user" }
            , { id: "bt_Asis", label: "Asistencia", align: "center", accion: this.btAsis, clsIcono: "fa fa-asterisk" }
            , { id: "bt_Lab", label: "Lab.", align: "center", accion: this.btLab, clsIcono: "fa fa-flask" }
            , { id: "bt_Fechas", label: "Fechas", align: "center", accion: this.btFechas, clsIcono: "fa fa-list" }
            , { id: "bt_Mate", label: "Mat.", align: "center", accion: this.btMaterial, clsIcono: "fa fa-sitemap" }
        ]
        return (<ContainerRightTable
            tituloContainer='Cursos Activos'
            titulos={titulos}
            resObj={this.state.resObj}
            contexto={this}
        >
            {true && this.fuPestana()}
        </ContainerRightTable>
        );
    }

    fuPestana() {
        return (<ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav" style={{ marginLeft: '20px' }}>
            <li className="nav-item">
                <a role="tab" className="nav-link active" id="tab-0" data-toggle="tab" href="#tab-prin">
                    <span>Principal</span>
                </a>
            </li>
            <li className="nav-item">
                <a role="tab" className="nav-link" id="tab-7" data-toggle="tab" href="#tab-descrip">
                    <span>Descripción</span>
                </a>
            </li>
            <li className="nav-item">
                <a role="tab" className="nav-link" id="tab-7" data-toggle="tab" href="#tab-pack">
                    <span>Packing</span>
                </a>
            </li>
        </ul>)
    }

}

export default WithNavigate(CursosActivos);
