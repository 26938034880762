const seccionesCerradosList='/secciones/cerrados'
const seccionesCerradosListRoute='/secciones/cerrados'
const seccionesActivosList='/secciones/activos'
const seccionesActivosListRoute='/secciones/activos'
const seccionesAlumnosRoute='/secciones/alumnos'
const seccionesGruposRoute='/secciones/grupos'
const seccionesAlumnosList='/secciones/alumnos/list'
const seccionesAluAsisList='/secciones/alumnos/asistencia/list'
const seccionesAsisList='/secciones/seccion/asistencia/list'
const seccionesLabList='/secciones/seccion/lab/list'
const seccionesLabEdit='/secciones/seccion/lab/edit'
const seccionesLabSave='/secciones/seccion/lab/save'
const seccionesLabFecList='/secciones/seccion/labfec/list'
const seccionesLabDetList='/secciones/seccion/lab/det/list'
const seccionesLabItemEdit='/secciones/seccion/lab/det/edit'
const seccionesLabItemSave='/secciones/seccion/lab/det/save'
const seccionesFechasRoute='/secciones/fechas'
const seccionesFechasAsistenciaRoute='/secciones/fechasasistencia'
const seccionesAsistenciaRoute='/secciones/seccionasistencia'
const seccionesLaboratoriosRoute='/secciones/seccionlaboratorios'
const seccionesLaborEditRoute='/secciones/seccionlaboredit'
const seccionesLaborItemRoute='/secciones/seccionlaboritem'
const seccionesFechasList='/secciones/fechas/list'
const seccionesAlumnoPasswordReset='/secciones/alumnos/resetpasswd'

const materialConteListRoute='/material/contelist'
const materialConteList='/material/contelist'
const materialAvanceTreeRoute='/material/avancetree'
const materialTreeLoad='/material/avancetree'
const materialTreeOk='/material/avancetreeok'
const materialTreeOkNo='/material/avancetreeokno'

const materialTreeViewRoute='/material/avancetreehtml'
const materialViewId='/material/materialviewid'
const materialViewIdEnabled='/material/materialviewidenabled'

const materialAvanceAlumnoRoute='/material/materialviewidavancealumno'
const materialAvanceAlumno='/material/materialviewidavancealumno'
module.exports = {
  seccionesCerradosList,
  seccionesCerradosListRoute,

  seccionesActivosList,
  seccionesActivosListRoute,
  seccionesAlumnosList,
  seccionesAluAsisList,
  seccionesAsisList,
  seccionesLabEdit,
  seccionesLabSave,
  seccionesLabList,
  seccionesLabDetList,
  seccionesLabFecList,
  seccionesLabItemEdit,
  seccionesLabItemSave,
  seccionesAlumnosRoute,
  seccionesGruposRoute,
  seccionesAlumnoPasswordReset,
  seccionesFechasRoute,
  seccionesAsistenciaRoute,
  seccionesLaboratoriosRoute,
  seccionesFechasAsistenciaRoute,
  seccionesFechasList,
  seccionesLaborEditRoute,
  seccionesLaborItemRoute,


  materialConteListRoute,
  materialConteList,
  materialAvanceTreeRoute,
  materialTreeLoad,
  materialTreeOk,
  materialTreeOkNo,

  materialTreeViewRoute,
  materialViewId,
  materialAvanceAlumnoRoute,
  materialAvanceAlumno,

  materialViewIdEnabled,
}
