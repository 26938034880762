import React, { Component  } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api  from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";

import ContainerRightTable  from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
class AvanceAlumnos extends HFormUser {
    getKey(){
    	return {idRs:this.props.location.state.detIdRs
             ,idCurso:this.props.location.state.detIdCurso
             ,idMateria:this.props.location.state.detIdMateria}
    }
    componentDidMount() {this.readApi();}

    readApi = ()=>{
        this.setState({loading: true})
        this.post(Api.materialAvanceAlumno,this.getKey())
          .then(data=>{this.stateHarrys(this,data)})    
    }  
    startCrear= iReg => {this.edit(-1)};
	render() { 
        
	    if (this.state.loading) return (<ShowLoading/>)
        const titulos = [  
                    { id:"fokclase" , label:"fokclase"    ,align:"right"}
                   , { id:"nombre" , label:"Nombre"   }
                 , { id:"paterno" , label:"Paterno"   }
                 , { id:"materno" , label:"Materno"   }
                 , { id:"fvisita" , label:"fvisita"    ,align:"right"}
                ]    
 		return (<ContainerRightTable 
                            tituloContainer='Revisión Alumnos '
                            titulos={titulos}
                            resObj={this.state.resObj}
                            contexto={this}
                            bt1Click={this.readApi}
                            bt1Icon="fas fa-sync"

                            >  
                </ContainerRightTable>  
            );
	 }

}	
export default WithNavigate(AvanceAlumnos);