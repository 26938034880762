import React, { Component } from "react";
import Api from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
class CusosAsistAlum extends HFormUser {
    getKey() {
        return { idRs: this.props.location.state.detRs.idrs, idClase: this.props.location.state.detIdClase }
    }
    readApi = () => {
        this.post(Api.seccionesAluAsisList, this.getKey())
            .then(data => { this.stateHarrys(this, data) })
    }
    componentDidMount() { this.readApi(); }


    render() {
        if (this.state.loading) return (<ShowLoading />)
        const titulos = [
              { id: "ape_pat", label: "Paterno", align: "left",  }
              , { id: "ape_mat", label: "Materno", align: "left",}
            , { id: "nombre", label: "Nombre", align: "left"}
            , { id: "st_presente", label: "Presente"
                , clsArrBoton: ["btn btn-outline-danger", "btn btn-success", "btn btn-warning", ""]
                , clsArrIcon: ["fa fa-close", "fa fa-check", "fa fa-sitemap", ""]
            }
            //, { id:"asistencia" , label:"Asistencia"   }
            //, { id:"bt_Reset", label:"pwd",align:"center",accion:this.btResPwd ,clsIcono:"fa fa-unlock"}
        ]
        const detRs = this.props.location.state.detRs;
        return (<ContainerRightTable
            tituloContainer={  detRs.ram_descrip 
                                  + " / " + detRs.ram_sigsec 
                                  + " / " + detRs.prof_nombre 
                                  + " / " + this.props.location.state.detRs.idrs
                                }
            titulos={titulos}
            resObj={this.state.resObj}
            contexto={this}
        >
        </ContainerRightTable>
        );
    }

}
export default WithNavigate(CusosAsistAlum);