import React, { Component } from "react";
import Api from "../ApiDocente";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";
import HBoxForm from "global/components/Page/InputHtml/HBoxForm";
import HBoxTextWord from "global/components/Page/InputHtml/HBoxTextWord";
import HBoxText from "global/components/Page/InputHtml/HBoxText";
import HBoxTextCod from "global/components/Page/InputHtml/HBoxTextCod";
import HBoxTextNum from "global/components/Page/InputHtml/HBoxTextNum";


class CursoSecLabItemEdit extends HFormUser {
    componentDidMount() { this.readApi(); }
    readApi() {
        this.post(Api.seccionesLabItemEdit, {
            detRs: this.props.location.state.detRs
        }).then(data => { this.stateHarrys(this, data) })
    }

    actualizarApi = () => {
        if (!this.validaForm()) return
        //console.log("lab",this.props.location.state.detRs)
        var stData = {
              idlabdet: this.props.location.state.detRs.idlabdet
            , idLab:  this.props.location.state.detRs.idlab
            , nroItem: document.getElementById("txNItem").value
            , codigo: document.getElementById("txCodigo").value
            , descCorta: document.getElementById("txDescCorta").value
            , descripcion: document.getElementById("txDescrip").value
            , ptje: document.getElementById("txPtje").value
        }
        this.post(Api.seccionesLabItemSave, stData).then(data => { if (data.ok) this.btVolver() })
    }

    render() {
        if (this.state.loading) return (<ShowLoading />)
        const detRs = this.props.location.state.detRs;
        return (<ContainerRightEdit
            tituloContainer={"Item " + detRs.ram_descrip + " / " + detRs.ram_sigsec + " => " + this.props.location.state.detRs.idrs}
            bt1Click={this.actualizarApi}
            bt1Icon="fas fa-save"
            contexto={this}
        >
            <HBoxForm>
                <HBoxTextNum
                    label="Nro.Item"
                    id="txNItem"
                    minLength="1"
                    value={this.state.data && this.state.data.nro_item}
                />
                <HBoxTextNum
                    label="Nro.Lab"
                    id="txNLab"
                    minLength="1"
                    value={this.props.location.state.detRs.idlab}
                />
                <HBoxTextNum
                    label="Puntaje"
                    id="txPtje"
                    title="Puntaje asignado"
                    style={{ width: '120px' }}
                    onClickHelp={this.helpRamo}
                    value={this.state.data && this.state.data.ptje}
                />
                <HBoxTextCod
                    label="Código"
                    id="txCodigo"
                    value={this.state.data && this.state.data.codigo}
                    maxLength="30"
                    size="30"
                />
                <HBoxText
                    label="Descripción Corta"
                    id="txDescCorta"
                    value={this.state.data && this.state.data.desc_corta}
                    maxLength="50"
                    size="50"
                />
                <HBoxTextWord
                    label="Descripción"
                    id="txDescrip"
                    value={this.state.data && this.state.data.descripcion}
                    maxLength="5000"
                    size="50"
                />

                <HBoxTextNum
                    label="IdLDet"
                    visibility={true}
                    id="txId"
                    style={{ width: '120px' }}
                    maxLength="20"
                    value={this.props.location.state.detRs.idlabdet}
                />
            </HBoxForm>
        </ContainerRightEdit>
        );
    }

}
export default WithNavigate(CursoSecLabItemEdit);