import React ,{Component} from "react";
import { Route } from "react-router-dom";
import HMainRouter  from "global/components/Page/MenuLTE/HMainRouter";

import Api  from "modDocente/ApiDocente";

import CursosActivosList from "modDocente/secciones/CursosActList";
import CursosActConteList from "modDocente/secciones/CursosActConteList";
import CursosCerradosList from "modDocente/secciones/CursosCerrList";
import CursosAlumnosList from "modDocente/secciones/CursosAlumnosList";
import CursosFechasList from "modDocente/secciones/CursosFechasList";

import CursosFechaAsis from "modDocente/secciones/CursosFechasAsis";
import CursosSeccionAsis from "modDocente/secciones/CursosSeccAsis";
import CursosSeccionLab from "modDocente/secciones/CursosSeccLab";
import CursosSeccionLabEdit from "modDocente/secciones/CursosSeccLabEdit";
import CursosSeccionLabItemEdit from "modDocente/secciones/CursosSeccLabItemEdit";

import MaterialAvanceTreeList from "modDocente/material/AvanceTreeList";
import MaterialAvanceTreeHtml from "modDocente/material/AvanceTreeHtml";
import MaterialAvanceAlumno from "modDocente/material/AvanceAlumnos";




class MainRouterMod  extends Component{
   constructor(props) {
      super(props);
      this.data=props;
    }
   render() {
      return (
         <HMainRouter>
               <Route exact path={Api.seccionesActivosListRoute} element={<CursosActivosList/>} />
               <Route exact path={Api.seccionesCerradosListRoute} element={<CursosCerradosList/>} />
               <Route exact path={Api.seccionesAlumnosRoute} element={<CursosAlumnosList/>} />
               <Route exact path={Api.seccionesFechasRoute} element={<CursosFechasList/>} />
               <Route exact path={Api.seccionesFechasAsistenciaRoute} element={<CursosFechaAsis/>} />
               <Route exact path={Api.seccionesAsistenciaRoute} element={<CursosSeccionAsis/>} />
               <Route exact path={Api.seccionesLaboratoriosRoute} element={<CursosSeccionLab/>} />
               <Route exact path={Api.seccionesLaborEditRoute} element={<CursosSeccionLabEdit/>} />
               <Route exact path={Api.seccionesLaborItemRoute} element={<CursosSeccionLabItemEdit/>} />

               

               <Route exact path={Api.materialConteListRoute} element={<CursosActConteList/>} />
               <Route exact path={Api.materialAvanceTreeRoute} element={<MaterialAvanceTreeList/>} />
               <Route exact path={Api.materialTreeViewRoute} element={<MaterialAvanceTreeHtml/>} />
               <Route exact path={Api.materialAvanceAlumnoRoute} element={<MaterialAvanceAlumno/>} /> 
         </HMainRouter>
         );
   }
}
export default MainRouterMod;