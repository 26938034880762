import React, { Component } from "react";
import Api from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
class CusosSeccionAsistencia extends HFormUser {

    getKey() {
        return { idRs: this.props.location.state.detRs.idrs, idClase: this.props.location.state.detIdClase }
    }
    readApi = () => {
        this.post(Api.seccionesAsisList, this.getKey())
            .then(data => { this.stateHarrys(this, data) })
    }
    componentDidMount() { this.readApi(); }


    render() {
        if (this.state.loading) return (<ShowLoading />)
        const titulos = [
              { id: "ape_pat", label: "Paterno"}
            , { id: "ape_mat", label: "Materno"}
            , { id: "nombre", label: "Nombre"}
            //, { id:"asistencia" , label:"Asistencia"   }
            //, { id:"bt_Reset", label:"pwd",align:"center",accion:this.btResPwd ,clsIcono:"fa fa-unlock"}
        ]
        let dataRow = []

        if (this.state.resObj.dataObj) {
            for (var x = 0; x < this.state.resObj.dataObj.fechas.length; x++) {
                const reg = this.state.resObj.dataObj.fechas[x]
                //console.log("reg",reg)
                //https://jsfiddle.net/N2748/4/
                titulos.push({
                    id: "st_fecha_" + x
                    //, label: reg.fclasereal==reg.fclaseprog?reg.fclaseprog:reg.fclaseprog +(reg.fclasereal==null?"": " - "+ reg.fclasereal)
                    , label: reg.fclaseprog
                    , clsNameTit: "rotVertical " + (reg.fclasereal==null?"text-dark":"text-primary")
                    , clsArrBoton: ["btn btn-outline-danger",  "btn btn-success" , "btn btn-warning","btn btn-primary" ]
                    , clsArrIcon: [ "fa fa-window-minimize",  "fa fa-check",  "fa fa-sitemap","fa fa-sitemap" ]
                })
            }
            //let dataRow = this.state.resObj.dataObj.asistencia
            for (var y = 0; y < this.state.resObj.dataObj.asistencia.length; y++) {
                let dataAux = this.state.resObj.dataObj.asistencia[y]
                for (var x = 0; x < this.state.resObj.dataObj.fechas.length; x++) {
                    if(this.state.resObj.dataObj.fechas[x].fclasereal==null)
                        if (this.state.resObj.dataObj.fechas[x].fmenornow == 1)
                            dataAux["st_fecha_" + x] = "2"
                        else 
                            dataAux["st_fecha_" + x] = "3"
                    else
                        dataAux["st_fecha_" + x] = this.state.resObj.dataObj.asistencia[y].asistencia.substring(x, x + 1)
                }
                dataRow.push(dataAux)
            }
        }
        const detRs = this.props.location.state.detRs;
        return (<ContainerRightTable
            tituloContainer={  detRs.ram_descrip + " / " + detRs.ram_sigsec + " / " + detRs.prof_nombre }
            titulos={titulos}
            rows={dataRow}
            contexto={this}
        >
        </ContainerRightTable>
        );
    }

}
export default WithNavigate(CusosSeccionAsistencia);