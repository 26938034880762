import React,{Component} from 'react';
import HBoxMsg  from "./HBoxMsg";
class HTextArea extends Component{
	constructor(props) {
     super(props);
     this.data=props;
     this.regp=  this.data.pattern || "^[a-zA-Z0-9 ñ.-_]+$"
     this.condition = new RegExp(this.regp);
     this.pattern = this.regp
     
     this.state = {value: "",errors: {},}; 
   }
  componentWillUpdate(nextProps, nextState){
    this.data=nextProps;
  }
  handleChange =( name)=> event => {
    let err = {};
    if (event.target.value.length < this.data.minLength) {
      err.msg = "Mínimo "+ this.data.minLength+ " caracteres";
    }
    else
    if (event.target.value.length > this.data.maxLength) {
      err.msg = "Máximo " + this.data.maxLength +" caracteres";
    }
    else{
      //const regp=  this.data.pattern || this.pattern
      //const condition = new RegExp(regp);
      let val= this.condition.test(event.target.value);
      //console.log("regp" + regp+ "==>"+ event.target.value + ":"+val)
      if(!val)
         err.msg = "Carácteres no válidos ";
    }    
    this.setState({ value: event.target.value});
    this.setState({ errors: err }, () => {
      if (Object.getOwnPropertyNames(this.state.errors).length === 0) {
        this.setState({ submitted: true });
      }
    });     
  };     
//  <label className="col-sm-3 control-label" >{this.data.label}</label>
//<HBoxMsg msg={this.state.errors.msg}/>
  render =()=>{
                    //onKeyPress={this.onKeyPress()}
	    return ( 

             <div className="col-md-12 col-sm-12 ">
						<div className="x_panel">
							<div className="x_title">
								<h2>{this.data.label}<small></small></h2>
								<ul className="nav navbar-right panel_toolbox">
									<li><a className="collapse-link"><i className="fa fa-chevron-up"></i></a>
									</li>
									<li className="dropdown">
										<a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false"><i className="fa fa-wrench"></i></a>
										<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
											<a className="dropdown-item" href="#">Settings 1</a>
											<a className="dropdown-item" href="#">Settings 2</a>
										</div>
									</li>
									<li><a className="close-link"><i className="fa fa-close"></i></a>
									</li>
								</ul>
								<div className="clearfix"></div>
							</div>
							<div className="x_content">
								<div id="alerts"></div>
								<div className="btn-toolbar editor" data-role="editor-toolbar" data-target="#editor-one">
									<div className="btn-group">
										<a className="btn dropdown-toggle" data-toggle="dropdown" title="Font"><i className="fa fa-font"></i><b className="caret"></b></a>
										<ul className="dropdown-menu">
										</ul>
									</div>

									<div className="btn-group">
										<a className="btn dropdown-toggle" data-toggle="dropdown" title="Font Size"><i className="fa fa-text-height"></i>&nbsp;<b className="caret"></b></a>
										<ul className="dropdown-menu">
											<li>
												<a data-edit="fontSize 5">
													<p style={{fontSize:"17px"}}>Huge</p>
												</a>
											</li>
											<li>
												<a data-edit="fontSize 3">
													<p style={{fontSize:"14px"}}>Normal</p>
												</a>
											</li>
											<li>
												<a data-edit="fontSize 1">
													<p style={{fontSize:"11px"}}>Small</p>
												</a>
											</li>
										</ul>
									</div>

									<div className="btn-group">
										<a className="btn" data-edit="bold" title="Bold (Ctrl/Cmd+B)"><i className="fa fa-bold"></i></a>
										<a className="btn" data-edit="italic" title="Italic (Ctrl/Cmd+I)"><i className="fa fa-italic"></i></a>
										<a className="btn" data-edit="strikethrough" title="Strikethrough"><i className="fa fa-strikethrough"></i></a>
										<a className="btn" data-edit="underline" title="Underline (Ctrl/Cmd+U)"><i className="fa fa-underline"></i></a>
									</div>

									<div className="btn-group">
										<a className="btn" data-edit="insertunorderedlist" title="Bullet list"><i className="fa fa-list-ul"></i></a>
										<a className="btn" data-edit="insertorderedlist" title="Number list"><i className="fa fa-list-ol"></i></a>
										<a className="btn" data-edit="outdent" title="Reduce indent (Shift+Tab)"><i className="fa fa-dedent"></i></a>
										<a className="btn" data-edit="indent" title="Indent (Tab)"><i className="fa fa-indent"></i></a>
									</div>

									<div className="btn-group">
										<a className="btn" data-edit="justifyleft" title="Align Left (Ctrl/Cmd+L)"><i className="fa fa-align-left"></i></a>
										<a className="btn" data-edit="justifycenter" title="Center (Ctrl/Cmd+E)"><i className="fa fa-align-center"></i></a>
										<a className="btn" data-edit="justifyright" title="Align Right (Ctrl/Cmd+R)"><i className="fa fa-align-right"></i></a>
										<a className="btn" data-edit="justifyfull" title="Justify (Ctrl/Cmd+J)"><i className="fa fa-align-justify"></i></a>
									</div>

									<div className="btn-group">
										<a className="btn dropdown-toggle" data-toggle="dropdown" title="Hyperlink"><i className="fa fa-link"></i></a>
										<div className="dropdown-menu input-append">
											<input className="span2" placeholder="URL" type="text" data-edit="createLink" />
											<button className="btn" type="button">Add</button>
										</div>
										<a className="btn" data-edit="unlink" title="Remove Hyperlink"><i className="fa fa-cut"></i></a>
									</div>

									<div className="btn-group">
										<a className="btn" title="Insert picture (or just drag & drop)" id="pictureBtn"><i className="fa fa-picture-o"></i></a>
										<input type="file" data-role="magic-overlay" data-target="#pictureBtn" data-edit="insertImage" />
									</div>

									<div className="btn-group">
										<a className="btn" data-edit="undo" title="Undo (Ctrl/Cmd+Z)"><i className="fa fa-undo"></i></a>
										<a className="btn" data-edit="redo" title="Redo (Ctrl/Cmd+Y)"><i className="fa fa-repeat"></i></a>
									</div>
								</div>

								<div id="editor-one" className="editor-wrapper">
                                    {/* ss */}
                                </div>
                <textarea name="descr" id="descr" style={{display:"none"}}></textarea>
                <textarea type="text" 
                    onChange={this.handleChange("value")} 
                    value={this.state.value|| this.data.value||""}
                    disabled={this.data.disabled|| false}
                    id={this.data.id} 
                    name={this.data.name || this.data.id} 
                    stylecc={this.data.style || {width:"450px"}} 
                    style={{display:"none1"}}
                    title={this.data.title}
                    pattern={this.data.pattern || "[0-9-]$"} 
                    minLength={this.data.minLength || 0} 
                    maxLength={this.data.maxLength || 1}  
                    className="resizable_textarea form-control" 
                    rows="4" cols="150"
                   >
                {this.state.value|| this.data.value||""}
              </textarea>
							</div>
						</div>
					</div>
      );
 	}  
 
}    
export default  HTextArea;