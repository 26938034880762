import React, { Component  } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api  from "../ApiDocente";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightTable  from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
import apiHelp from "global/utils/funHelp";
class CusosActivos extends HFormUser {
    readApi = (stParamDict)=>{
        this.post(Api.seccionesCerradosList,{qryFiltro:stParamDict})
          .then(data=>{this.stateHarrys(this,data)})    
    }  
    componentDidMount() {this.readApi([]);} 
    startSearch = bus=>{ this.readApi(apiHelp.getTextSearch())} 
    edit = idReg => {
      this.props.history.push({pathname:Api.cursosActivosEditRoute
                       ,state: { detKey: idReg }
                     });
    };       
    mate = idReg => {
        this.props.history.push({pathname:Api.curMaterialTreeRoute
                         ,state: { detKey: idReg }
                       });
    };       
      
	render() { 
	    if (this.state.loading) return (<ShowLoading/>)
        const titulos = [  
                   { id:"ram_sigsec" , label:"Sigla"   ,style:{width:"100px"}}
                 , { id:"ram_descrip" , label:"Descripción"   }
                 , { id:"per_sigla" , label:"Periodo"    ,align:"right",style:{width:"100px"}}
                 //, { id:"bt_Mat", label:"Mat",align:"center",accion:this.mate,clsIcono:"fas fa-paperclip"}
                 //, { id:"bt_Asig", label:"Ed",align:"center",accion:this.edit,clsIcono:"fas fa-clipboard-check"}
                ]    
 		return (<ContainerRightTable 
                            tituloContainer='Cursos Realizados'
                            titulos={titulos}
                            resObj={this.state.resObj}
                            filasPorPagina={250}
                            >  
                </ContainerRightTable>  
            );
	 }

}	
export default WithNavigate(CusosActivos);