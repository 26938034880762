import React, { Component } from "react";
import Api from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
class CusosSeccionLab extends HFormUser {
    componentDidMount() { this.readApi(); }

    getKey() {
        //console.log(this.props.location.state.detRs)
        return { idRs: this.props.location.state.detRs.idrs, idClase: this.props.location.state.detIdClase }
    }
    readApi = () => {
        this.post(Api.seccionesLabList, this.getKey())
            .then(data => { this.stateHarrys(this, data) })
    }
    startCrear = () => {
        apiRuta.toRutaAdd(this, "Nuevo"
                   , Api.seccionesLaborEditRoute
                   , { detRs: this.props.location.state.detRs,row:{idlab:-1}})
    }
    btEdit = (row) => {
        apiRuta.toRutaAdd(this, "Edit"
                    , Api.seccionesLaborEditRoute
                    , { detRs: this.props.location.state.detRs,row:row })
    }

    render() {
        if (this.state.loading) return (<ShowLoading />)
        const titulos = [
                { id: "nro_labor", label: "Nro.",style:{width:"50px"}}
              , { id: "idclase", label: "Nro.Clase", align: "right",style:{width:"50px"}}
              , { id: "descripcion", label: "Descripcion"}
              , { id: "fecinicio", label: "Inicio"}
              , { id: "fecfin", label: "Fin"}
              , { id: "ptje", label: "Ptje.",style:{width:"50px"}, align: "right"}
              , { id: "bt_Edit", label: "Editar", align: "center", accion: this.btEdit, clsIcono: "fa fa-edit" }
        ]
        const detRs = this.props.location.state.detRs;
        return (<ContainerRightTable
            tituloContainer={  detRs.ram_descrip + " / " + detRs.ram_sigsec + " / " + detRs.prof_nombre }
            titulos={titulos}
            rows={this.state.resObj.dataObj && this.state.resObj.dataObj.labor}
            contexto={this}
            bt1Click={this.startCrear}
            bt1Icon="fas fa-folder-plus"
            bt1Class="btn-danger"
        >
        </ContainerRightTable>
        );
    }

}
export default WithNavigate(CusosSeccionLab);