import Api from 'modDocente/ApiDocente';
const _menu =
{
    name: 'Dashboard',
    arMenu: [{
        titulo: "Cursos", icon: "fa fa-bug"
        , menNiv: [
            { titulo: "Activos", ruta: Api.seccionesActivosListRoute, icon: "fa fa-bug" }
            , { titulo: "Finalizados", ruta: Api.seccionesCerradosListRoute, icon: "fa fa-bug" }
            , {
                titulo: "Exámenes", icon: "fa fa-bug"
                , menNiv: [{
                    titulo: "Ex Niv 1", icon: "fa fa-bug"
                    , menNiv: [{ titulo: "Activos Niv4", ruta: Api.seccionesActivosListRoute, icon: "fa fa-bug" }
                        , { titulo: "Finalizados Niv4", ruta: Api.seccionesCerradosListRoute, icon: "fa fa-bug" }]
                }
                    , { titulo: "Ex Niv 2", ruta: Api.seccionesActivosListRoute }
                ]
            }

        ]
    }

        , {
        titulo: "Portal Academico", icon: "fa fa-bug", menNiv: [
            { titulo: "Alumno", ruta: "http://alumno.portalacademico.cl", icon: "fa fa-bug" }
            , { titulo: "Docente", ruta: "http://docente.portalacademico.cl", icon: "fa fa-bug" }
            , { titulo: "Exámenes", ruta: "http://examen.portalacademico.cl/", icon: "fa fa-bug" }
            , { titulo: "Material", ruta: "http://material.portalacademico.cl", icon: "fa fa-bug" }
            , { titulo: "Administrador", ruta: "http://admin.portalacademico.cl/", icon: "fa fa-bug" }
            , { titulo: "WWW", ruta: "http://www.portalacademico.cl/", icon: "fa fa-bug" }
            , { titulo: "Mis Datos", ruta: "https://hcm19.sapsf.com/", icon: "fa fa-bug" }
            

        ]
    }
        , {
        titulo: "Portal Duoc", icon: "fa fa-bug", menNiv: [
            { titulo: "Notas", ruta: "http://notas.duoc.cl/Notas/", icon: "fa fa-bug" }
            , { titulo: "Asistencia", ruta: "https://asistenciaclases.duoc.cl/", icon: "fa fa-bug" }
            , { titulo: "Campos Virtual", ruta: "https://campusvirtual.duoc.cl/", icon: "fa fa-bug" }
            , { titulo: "Cambiar Clave", ruta: "https://account.activedirectory.windowsazure.com/ChangePassword.aspx", icon: "fa fa-bug" }
            , { titulo: "liveProfile", ruta: "https://hcm19.sapsf.com/sf/liveprofile", icon: "fa fa-bug" }

            , { titulo: "Gmail", ruta: "http://www.duoc.cl/pd/mis_sistemas/gmail", icon: "fa fa-bug" }
            , { titulo: "ww.duoc.cl", ruta: "http://www.duoc.cl/", icon: "fa fa-bug" }
            , { titulo: "Contratos", ruta: "https://5.dec.cl/", icon: "fa fa-bug" }
            , { titulo: "Eval. Transversal", ruta: "https://evaluaciones.duoc.elapoint.net/Desktop/", icon: "fa fa-bug" }
            , { titulo: "openagora", ruta: "https://duoc.openagora.cl/", icon: "fa fa-bug" }
            , { titulo: "Cape PIAS", ruta: "https://www2.duoc.cl/cape_publisher/", icon: "fa fa-bug" }
            , { titulo: "Eval.Coordinación", ruta: "https://coordinacionlinea.gruposentte.cl/", icon: "fa fa-bug" }

        ]
    }
        , {
        titulo: "Programación", icon: "fa fa-bug", menNiv: [
            { titulo: "TAV", ruta: "https://inscripciones.duoc.cl/TAV/", icon: "fa fa-bug" }
            , { titulo: "IA", ruta: "https://inscripciones.duoc.cl/IA/", icon: "fa fa-bug" }

        ]
    }





    ]
}

export default _menu
