import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";

class MaterialTreeHtml extends HFormUser {

    componentDidMount() {  this.readApi(this.props.location.state.detKeyItem, 0); }
    getKey(idKeyPar, masmen) {
        return {
            idRs: this.props.location.state.detIdRs
            , idCurso: this.props.location.state.detIdCurso
            , idMateria: this.props.location.state.detIdMateria
            , idKey: idKeyPar
            , sumar: masmen
        }
    }
    getKeyAlu() {
        return {
            detIdRs: this.props.location.state.detIdRs
            , detIdCurso: this.props.location.state.detIdCurso
            , detIdMateria: this.state.data.idmateria
            , detKeyItem: this.state.data.idmateria
        }
    }
    readApi(keyPar, masmen) {
        //console.log("ss******************",this.props.location.state)
        this.setState({ loading: true })
        this.post(Api.materialViewId, this.getKey(keyPar, masmen))
            .then(data => {
                this.stateHarrys(this, data)
                apiRuta.toUpdateRutaLast(this, this.getKeyAlu())
            })
    }
    btVerAlumnos = () => { apiRuta.toRutaAdd(this, "Control", Api.materialAvanceAlumnoRoute, this.getKeyAlu()) }
    btSiguiente = () => { this.readApi(this.state.data.idmateria, 1) }
    btSiguienteOk = () => { this.readApi(this.state.data.idmateria, 99) }
    btAnterior = () => { this.readApi(this.state.data.idmateria, -1) }
    btEnabled = () => {
        this.setState({ loading: true })
        this.post(Api.materialViewIdEnabled, this.getKey(this.state.data.idmateria, 0))
            .then(data => {
                this.stateHarrys(this, data)
            })
    }
    render() {
        if (this.state.loading || !this.state.data) return (<ShowLoading />)
        return (<>

            {this.fuBarraAvanza()}

            <ContainerRightEdit
                msgErr={this.state.resObj}
                styleCol12={{ width: '100%', height: '100%' }}
                tituloContainer={this.state.data && (this.state.data.descripcion)}
                bt1Icon="fas fa-eye"
                bt1Click={this.btVerAlumnos}
                contexto={this}
            >
                <form className="form-horizontal well" id="forms-registro">
                    <fieldset>
                        <div className="editor" style={{ marginLeft: '15px', marginRight: '15px' }}
                            dangerouslySetInnerHTML={{ __html: this.state.data.observ }}>
                        </div>
                    </fieldset>
                </form>
            </ContainerRightEdit>
        </>
        );
    }


    fuBarraAvanza() {
        return (<>
            <div className="card">
                <div className="card-header d-flex align-items-center" style={{ backgroundColor: "white", height: "50px" }}>
                    <a className="nav-link bg-light" onClick={this.btSiguienteOk} data-widget="iframe-scrollrightxx">
                        <i className="fas fa-lg fa-fighter-jet"></i></a>
                    <div className="card-actions cursor-pointer ms-auto d-flex button-group" style={{paddingTop:'15px'}}>
                        <div className="float-right ">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link link"
                                        onClick={this.btAnterior}
                                        aria-label="Previous">
                                        <span aria-hidden="true">
                                            <i class="ti ti-chevrons-left fs-4"></i>
                                        </span>
                                    </a>
                                </li>
                                {this.state.data.swokclase_cls != 1 && (<li class="page-item">
                                    <a class="page-link link"
                                        onClick={this.btEnabled}>
                                        <i className="far fa-lg fa-thumbs-up"></i>
                                    </a>
                                </li>)}

                                {this.state.data.swokclase_cls == 1 && (<li class="page-item">
                                    <a class="page-link link"
                                        onClick={this.btEnabled} >
                                        <i className="far fa-lg fa-thumbs-down">
                                        </i></a>
                                </li>)}
                                <li class="page-item">
                                    <a class="page-link link"
                                        onClick={this.btSiguiente}
                                        aria-label="Next">
                                        <span aria-hidden="true">
                                            <i class="ti ti-chevrons-right fs-4"></i>
                                        </span>
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }

}

export default WithNavigate(MaterialTreeHtml);