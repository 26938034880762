import React, { Component  } from "react";
import Api  from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightTable  from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
class CusosActivos extends HFormUser {

    getKey(){
     return {idRs:this.props.location.state.detRs.idrs,idClase:this.props.location.state.detIdClase}
    }
    readApi = ()=>{
        this.post(Api.seccionesAlumnosList,this.getKey())
          .then(data=>{this.stateHarrys(this,data)})    
    }  
    componentDidMount() {this.readApi();}
    startSearch = bus=>{ this.readApi()} 
    startCrear= iReg => {this.edit(-1)};
    edit = row => {
        apiRuta.toRutaAdd(this,"Contenidos",Api.seccionesAlumnosRoute,{ detKey: row.idReg })
    };       
    btResPwd = (row)=>{
        this.setState({loading: true})
        console.log(row)
        this.post(Api.seccionesAlumnoPasswordReset,{idPersona:row.idalumno,idRs:this.props.location.state.detRs.idrs,idClase:this.props.location.state.detIdClase})
                            .then(data=>{this.stateHarrys(this,data)})    
    }

	render() { 
	    if (this.state.loading) return (<ShowLoading/>)
        const detRs = this.props.location.state.detRs;
        const titulos = [  
             { id:"ape_pat" , label:"Paterno"  }
            , { id:"ape_mat" , label:"Materno" }
            , { id:"nombre" , label:"Nombre"   }
            , { id:"run" , label:"Run",align:"right"   }
            , { id:"user_name" , label:"Usuario",align:"right"   }
                 , { id:"bt_Reset", label:"Key",align:"center",accion:this.btResPwd ,clsIcono:"fa fa-unlock"}
                ]    
 		return (<ContainerRightTable 
                            tituloContainer={  detRs.ram_descrip + " / " + detRs.ram_sigsec + " / " + detRs.prof_nombre }
                            titulos={titulos}
                            resObj={this.state.resObj}
                            contexto={this}
                            >  
                </ContainerRightTable>  
            );
	 }

}	
export default WithNavigate(CusosActivos);