import React, { Component  } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api  from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightTable  from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
class CusosActContent extends HFormUser {
    getKey = ()=>{
        return {idRs:this.props.location.state.detRs.idrs,idCurso:this.props.location.state.detIdCurso}
    }
    readApi = ()=>{
        //console.log("==============",this.props.location.state)
        this.post(Api.materialConteList,this.getKey())
          .then(data=>{this.stateHarrys(this,data)})    
    }  
    componentDidMount() {this.readApi();}
    startSearch = bus=>{ this.readApi()} 
    startCrear= iReg => {this.edit(-1)};
    edit = idReg => {/*
      this.props.history.push({pathname:Api.seccionesAlumnosRoute
                       ,state: { detKey: idReg }
                     });*/
    };       
    btMaterial = row => {
        apiRuta.toRutaAdd(this,"Material",Api.materialAvanceTreeRoute
                 ,{detIdRs:this.props.location.state.detRs.idrs
                  ,detIdCurso:this.props.location.state.detIdCurso
                  ,detIdMateria: row.idmateria }
                 )
    };       
    
	  render() { 
	    if (this.state.loading) return (<ShowLoading/>)
        const titulos = [  
                   { id:"codigo" , label:"Sigla"   }
                 , { id:"descripcion" , label:"Descripción"   }
                 , { id:"bt_Mate", label:"Material",align:"center",accion:this.btMaterial ,clsIcono:"fas fa-sitemap"}
                ]    
        const detRs = this.props.location.state.detRs;
 		return (<ContainerRightTable 
                            tituloContainer={  detRs.ram_descrip + " / " + detRs.ram_sigsec + " / " + detRs.prof_nombre }
                            titulos={titulos}
                            resObj={this.state.resObj}
                            bt1={this.btVolver}
                            bt1Icon="fa fa-backward"
                            contexto={this}
                            >  
                </ContainerRightTable>  
            );
	 }

}	
export default WithNavigate(CusosActContent);