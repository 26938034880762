import React, { Component } from "react";
import Api from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";
import HBoxForm from "global/components/Page/InputHtml/HBoxForm";
import HBoxTextWord from "global/components/Page/InputHtml/HBoxTextWord";
import HBoxTextNum from "global/components/Page/InputHtml/HBoxTextNum";
import HBoxTextFecha from "global/components/Page/InputHtml/HBoxTextFecha";
import HBoxSelect from "global/components/Page/InputHtml/HBoxSelect";
import DataTable from "global/components/Page/DataTable/DataTableMaterial";


class CursoSecLabEdit extends HFormUser {
    componentDidMount() { this.readApi(); }
    readApi() {
        this.setState({ dataClase:null,dataDet: null,total : this.state.total + 1})
        this.post(Api.seccionesLabEdit, {
               idRs: this.props.location.state.detRs.idrs
              , idLab: this.props.location.state.row.idlab
        }).then(data => { this.stateHarrys(this, data) })
        this.readClase()
        this.readDetalle()
    }

    actualizarApi = () => {
        if (!this.validaForm()) return
        var stData = {
            idRs: this.props.location.state.detRs.idrs
            , idLab: this.props.location.state.row.idlab
            , nroLabor: document.getElementById("txNLab").value
            , descripcion: document.getElementById("txDescrip").value
            , ptje: document.getElementById("txPtje").value
            , idClase: document.getElementById("cbNroCla").value
            , fIni: document.getElementById("txFIni").value
            , fFin: document.getElementById("txFFin").value
        }
        this.post(Api.seccionesLabSave, stData).then(data => { if (data.ok) this.btVolver() })
    }

    readClase() {
        //this.setState({ total : this.state.total + 2})
        this.post(Api.seccionesFechasList
            , { idRs: this.props.location.state.detRs.idrs })
            .then(data => { this.setState({ dataClase: data.rows }) })
    }
    readDetalle() {
        //this.setState({ total : this.state.total + 3})
        this.post(Api.seccionesLabDetList
            , { idRs: this.props.location.state.detRs.idrs })
            .then(data => { this.setState({ dataDet: data.rows }) })
    }
    crearDet = () => {
        this.btEdit({idlabdet:-1,idlab:this.props.location.state.row.idlab})
    }
    btEdit= (row) => {
        apiRuta.toRutaAdd(this, "Item"
                    , Api.seccionesLaborItemRoute
                    , { detRs:row })
                               
    }
    render() {
        if (this.state.loading || this.state.dataDet == null || this.state.dataClase==null) 
            return (<ShowLoading />)
        const titulos = [
              { id: "nro_item", label: "Nro.", style: { width: "20px" } }
              , { id: "codigo", label: "Código"}
              , { id: "desc_corta", label: "Desc. Corta"}
              , { id: "descripcion", label: "Descripción"}
              , { id: "ptje", label: "Ptje.", align: "right", style: { width: "50px" } }
              , { id: "bt_Edit", label: "Editar", align: "center", accion: this.btEdit, clsIcono: "fa fa-edit" }
        ]
        const detRs = this.props.location.state.detRs;
        return (<ContainerRightEdit
            tituloContainer={detRs.ram_descrip + " / " + detRs.ram_sigsec + " => " + this.props.location.state.detRs.idrs}
            bt1Click={this.actualizarApi}
            bt1Icon="fas fa-save"
            contexto={this}
        >
            <HBoxForm>
                <HBoxTextNum
                    label="Nro.Laboratorio"
                    id="txNLab"
                    minLength="1"
                    value={this.state.data && this.state.data.nro_labor}
                />
                <HBoxTextNum
                    disabled
                    label="Puntaje"
                    id="txPtje"
                    title="Puntaje asignado"
                    style={{ width: '120px' }}
                    value={this.state.data && (this.state.data.ptje )}
                />
                <HBoxTextWord
                    label="Descripción"
                    id="txDescrip"
                    value={this.state.data && this.state.data.descripcion}
                    maxLength="100"
                    size="50"
                />
                <HBoxSelect
                    label="Nro.Clase"
                    id="cbNroCla"
                    value={this.state.data && this.state.data.idclase}
                    option={this.state.dataClase}
                />
                <HBoxTextFecha label="Fecha de Inicio"
                    id="txFIni"
                    value={this.state.data && this.state.data.fecinicio} />
                <HBoxTextFecha label="Fecha de Termino"
                    id="txFFin"
                    value={this.state.data && this.state.data.fecfin} />
                <HBoxTextNum
                    label="Id"
                    visibility={false}
                    id="txId"
                    style={{ width: '120px' }}
                    maxLength="20"
                    value={this.props.location.state.row.idlab}
                />
                <DataTable key="kk_detail"
                    contexto={this}
                    rows={this.state.dataDet}
                    titulo="items"
                    titulos={titulos}
                    bt1Click={this.crearDet}
                    bt1Icon="fas fa-plus"
                />
            </HBoxForm>
        </ContainerRightEdit>
        );
    }

}
export default WithNavigate(CursoSecLabEdit);