import React, { Component  } from "react";
import Api  from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightTable  from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
class CusosFechas extends HFormUser {
	
    getKey(){
     return {idRs:this.props.location.state.detRs.idrs}
    }
    readApi = ()=>{
        this.post(Api.seccionesFechasList,this.getKey())
          .then(data=>{this.stateHarrys(this,data)})    
    }  
    componentDidMount() {this.readApi();}
    startSearch = bus=>{ this.readApi()} 
    startCrear= iReg => {this.edit(-1)};
    btAsistencia = row => {
        apiRuta.toRutaAdd(this,"Asistencia",Api.seccionesFechasAsistenciaRoute,{ detRs: this.props.location.state.detRs , detIdClase: row.idclase})
     };       
	render() { 
      if (this.state.loading) return (<ShowLoading/>)
        const titulos = [  
                   { id:"idclase" , label:"Nro."   }
                 , { id:"fclaseprog" , label:"Programada"   }
                 , { id:"fclasereal" , label:"Realizada"   }
                 , { id:"ccevi" , label:"Evi.",style:{width:"30px"}   }
                 , { id:"cclab" , label:"Lab." ,style:{width:"30px"}  }
                 , { id:"fmenornow" , label:"Menor." ,style:{width:"130px"}  }
                 , { id:"bt_Asig", label:"Asistencia",align:"center",accion:this.btAsistencia ,clsIcono:"fa fa-edit"}
                ]    
        const detRs = this.props.location.state.detRs;
 		return (<ContainerRightTable 
                            tituloContainer={  detRs.ram_descrip + " / " + detRs.ram_sigsec + " / " + detRs.prof_nombre }
                            titulos={titulos}
                            resObj={this.state.resObj}
                            contexto={this}
                            >  
                </ContainerRightTable>  
            );
	 }

}	
export default WithNavigate(CusosFechas);